<template>
  <b-modal
    size="lg"
    id="modal-history-detail"
    centered
    hide-footer
    title="Detail History Transaksi"
  >
    <div class="pt-md-3 px-lg-3 px-2 pb-3" style="letter-spacing: 0.3px">
      <!-- Logo -->
      <b-img
        v-if="
          historyDetail.branch &&
          historyDetail.branch.photo_url &&
          (historyDetail.branch.photo_url.includes('png') ||
            historyDetail.branch.photo_url.includes('jpg'))
        "
        :src="historyDetail.branch.photo_url"
        alt="logo"
        class="custom-img image-android"
        style="width: 100px; height: auto"
      />

      <!-- Detail -->
      <b-row class="mt-2">
        <b-col lg="12">
          <h6
            class="size10 fw-bold-700 text-black mb-1"
            v-if="historyDetail.branch"
          >
            {{ historyDetail.branch.name || "-" }}
          </h6>
          <h6
            class="size10 fw-bold-700 text-black mb-1"
            v-if="historyDetail.branch"
          >
            {{ historyDetail.branch.address || "-" }}
          </h6>
          <h6
            class="size10 fw-bold-700 text-black mb-1"
            v-if="historyDetail.branch"
          >
            Tlp: {{ historyDetail.branch.phone }}
          </h6>
          <h6 class="size10 fw-bold-700 text-black mb-1">
            No. Invoice: {{ historyDetail.invoice_number || "-" }}
          </h6>
          <h6
            v-if="historyDetail.po_so_number"
            class="size10 fw-bold-700 text-black mb-1"
          >
            No. PO SO: {{ historyDetail.po_so_number || "-" }}
          </h6>
          <h6
            v-if="deliveryNumbers && deliveryNumbers.length"
            class="size10 fw-bold-700 text-black mb-1"
          >
            No. Surat Jalan: {{ deliveryNumbers.join(", ") || "-" }}
          </h6>
          <h6 class="size10 fw-bold-700 text-black mb-1">
            Tanggal: {{ historyDetail.date || "-" }}
          </h6>
        </b-col>
        <b-col md="12" lg="12">
          <h6 class="size10 fw-bold-700 text-black mb-1">
            Sales:
            {{ (historyDetail.seller && historyDetail.seller.name) || "-" }}
          </h6>
          <h6 class="size10 fw-bold-700 text-black mb-1">
            Kasir:
            {{ (historyDetail.operator && historyDetail.operator.name) || "-" }}
          </h6>
          <h6
            v-if="historyDetail.customer"
            class="size10 fw-bold-700 text-black mb-1"
          >
            Pelanggan: {{ historyDetail.customer.name }}
          </h6>
          <div
            v-if="historyDetail.delivery_pickup === 'delivery'"
            class="d-flex"
          >
            <h6 class="size10 fw-bold-700 text-black text-capitalize">
              Pengiriman: {{ historyDetail.delivery_pickup || "-" }}
            </h6>
          </div>
        </b-col>
        <b-col lg="12">
          <hr class="mb-25" style="border-top: 1px solid #000" />
        </b-col>
      </b-row>

      <!-- List Produk -->
      <b-row>
        <b-col lg="12">
          <table
            class="table table-borderless"
            v-for="(item, index) in historyDetail.items"
            :key="index"
          >
            <tr>
              <td colspan="4" class="p-0">
                <h6 class="size10 fw-bold-700 text-black px-0">
                  {{ item.name }}
                </h6>
              </td>
            </tr>
            <tr>
              <td class="px-0" style="width: 40%">
                <h6 class="size10 fw-bold-700 text-black px-0">
                  {{ item.qty.toString().replace(".", ",") }}
                  {{ item.unit }}
                </h6>
              </td>
              <td class="px-0">
                <h6 class="size10 fw-bold-700 text-black px-0">
                  {{ item.sub_price | formatInvoice }}
                </h6>
              </td>
              <td class="px-0 text-center">
                <h6
                  class="size10 fw-bold-700 text-black px-0"
                  v-if="item.discount_per_item > 1"
                >
                  -{{ item.discount_per_item | formatInvoice }}
                </h6>
                <h6 class="size10 fw-bold-700 text-black px-0" v-else></h6>
              </td>
              <td class="px-0">
                <h6 class="size10 fw-bold-700 text-black px-0 text-right">
                  {{ item.total | formatInvoice }}
                </h6>
              </td>
            </tr>
          </table>
        </b-col>
        <b-col lg="12">
          <hr class="mt-25" style="border-top: 1px solid #000" />
        </b-col>
      </b-row>

      <!-- Subtotal, Retur, dll -->
      <b-row>
        <b-col lg="12">
          <div
            class="d-flex justify-content-between"
            style="margin-bottom: 0.5rem"
          >
            <h6 class="text-black size10 mb-0 fw-bold-700" style="width: 100px">
              Subtotal
            </h6>
            <h6 class="text-black size10 mb-0 fw-bold-700">
              {{ historyDetail.subtotal | formatAmountAndroid }}
            </h6>
          </div>
          <!-- Biaya Tambahan -->
          <div
            v-if="historyDetail.additional_fee_total > 0"
            class="d-flex justify-content-between"
            style="margin-bottom: 0.5rem"
          >
            <h6 class="text-black size10 mb-0 fw-bold-700">
              Total Biaya Tambahan
            </h6>
            <h6 class="text-black size10 mb-0 fw-bold-700">
              {{ historyDetail.additional_fee_total | formatAmountAndroid }}
            </h6>
          </div>
          <info-additional-cost :historyDetail="historyDetail" />

          <!-- Split Payment -->
          <hr
            v-if="historyDetail.payments && historyDetail.payments.length > 1"
            class="mb-25"
            style="border-top: 1px solid #000"
          />
          <h6
            class="text-black size10 fw-bold-700"
            v-if="historyDetail.payments && historyDetail.payments.length > 1"
          >
            Pembayaran Terbagi
          </h6>
          <div
            class="d-flex justify-content-between"
            v-for="(payment, indexPayment) in historyDetail.payments"
            :key="indexPayment"
          >
            <h6 class="text-black size10 mb-0 text-capitalize">
              {{ paymentMethods(payment.payment_method) }}
              {{
                payment.payment_method == "transfer"
                  ? " ke " + payment.bank_account_payment.bank.name
                  : ""
              }}
            </h6>
            <h6 class="text-black size10">
              {{ parseFloat(payment.amount) | formatAmountAndroid }}
            </h6>
          </div>
          <hr
            v-if="historyDetail.payments && historyDetail.payments.length > 1"
            class="mt-0 mb-25"
            style="border-top: 1px solid #000"
          />
          <!-- Total Split Payment -->
          <div
            v-if="historyDetail.payments && historyDetail.payments.length > 1"
            class="d-flex justify-content-between"
            style="margin-bottom: 0.5rem"
          >
            <h6 class="text-black size10 mb-0">Total Pembayaran</h6>
            <h6 class="size10 text-black mb-0">
              {{
                sumSplitPayment(historyDetail.payments) | formatAmountAndroid
              }}
            </h6>
          </div>
          <!-- Diskon -->
          <div
            v-if="historyDetail.discount_value > 0"
            class="d-flex justify-content-between"
            style="margin-bottom: 0.5rem"
          >
            <h6 class="text-black size10 mb-0">Diskon Tambahan</h6>
            <h6
              v-if="historyDetail.discount_type == 'fixed'"
              class="size10 text-black mb-0"
            >
              - {{ historyDetail.discount_value | formatAmountAndroid }}
            </h6>
            <h6
              v-if="historyDetail.discount_type == 'percent'"
              class="size10 text-black mb-0"
            >
              - {{ historyDetail.discount_value }}%
            </h6>
          </div>
          <div
            v-if="historyDetail.discount_total > 0"
            class="d-flex justify-content-between"
            style="margin-bottom: 0.5rem"
          >
            <h6 class="text-black size10 mb-0 fw-bold-700">Total Diskon</h6>
            <h6 class="text-black size10 mb-0 fw-bold-700">
              {{ historyDetail.discount_total | formatAmountAndroid }}
            </h6>
          </div>
          <div
            class="d-flex justify-content-between"
            style="margin-bottom: 0.5rem"
          >
            <h6 class="text-black size12 mb-0 fw-bold-700">Grand Total</h6>
            <h6 class="text-black size10 mb-0 fw-bold-700">
              {{ historyDetail.total | formatAmountAndroid }}
            </h6>
          </div>
          <div
            v-if="
              historyDetail.payment_method !== 'piutang' &&
              historyDetail.piutang &&
              historyDetail.piutang.amount > 0
            "
            class="d-flex justify-content-between"
            style="margin-bottom: 0.5rem"
          >
            <h6 class="text-black size10 mb-0 text-capitalize">Piutang</h6>
            <h6 class="text-black size10 mb-0">
              {{
                historyDetail.piutang &&
                historyDetail.piutang.amount | formatAmountAndroid
              }}
            </h6>
          </div>
          <div
            v-if="historyDetail.payment_method !== 'piutang'"
            class="d-flex justify-content-between"
            :style="
              historyDetail.payments && historyDetail.payments.length > 1
                ? 'margin-bottom: 0.5rem;'
                : ''
            "
          >
            <h6
              class="text-black size10 mb-0"
              v-if="historyDetail.payment_method === 'wallet'"
            >
              Sisa Deposit
            </h6>
            <h6
              class="text-black size10 mb-0"
              v-if="historyDetail.payment_method === 'wallet'"
            >
              {{
                historyDetail.payment_customer &&
                historyDetail.payment_customer.wallet_balance
                  | formatAmountAndroid
              }}
            </h6>
            <div
              class="d-flex justify-content-between w-100 mb-25"
              v-if="
                historyDetail.cash_change > 0 ||
                (historyDetail.returs_id && historyDetail.returs_id.length)
              "
            >
              <h6 class="text-black size10 mb-0">Kembali</h6>
              <h6 class="text-black size10 mb-0">
                {{ historyDetail.cash_change | formatAmountAndroid }}
              </h6>
            </div>
          </div>
          <div
            v-if="historyDetail.discount_total > 0"
            class="d-flex justify-content-between"
            style="margin-bottom: 0.5rem"
          >
            <h6 class="text-black size10 mb-0">Anda Hemat</h6>
            <h6 class="text-black size10 mb-0">
              {{ historyDetail.discount_total | formatAmountAndroid }}
            </h6>
          </div>
        </b-col>
        <b-col lg="12">
          <div class="d-flex justify-content-between my-25">
            <h6 class="size10 fw-bold-700 text-black mt-3 mt-lg-2 mb-0">
              Note:
            </h6>
            <h6 class="size10 fw-bold-700 text-black" />
          </div>
          <div class="d-flex justify-content-between">
            <h6
              class="size10 fw-bold-700 text-black mb-0 font-italic"
              v-if="historyDetail.display_vat === 1"
            >
              *Harga barang sudah termasuk PPn 11%
            </h6>
            <h6 class="size10 fw-bold-700 text-black" />
          </div>
          <div class="d-flex justify-content-between">
            <h6 class="size10 fw-bold-700 text-black mb-lg-2 font-italic">
              {{ historyDetail.invoice_notes }}
            </h6>
            <h6 class="size10 fw-bold-700 text-black" />
          </div>
        </b-col>
        <b-col lg="12">
          <h6
            v-if="
              historyDetail.returs_id &&
              historyDetail.returs_id &&
              historyDetail.returs_id.length > 0
            "
            class="size10 fw-bold-700 text-black"
          >
            {{ historyDetail.returs_id.join(", ") }}
          </h6>
        </b-col>
        <b-col lg="12">
          <h6
            v-if="historyDetail.deleted_by"
            class="size10 fw-bold-700 text-danger"
          >
            Dibatalkan oleh : {{ historyDetail.deleted_by.name }} /
            {{ historyDetail.deleted_at }}
          </h6>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import InfoAdditionalCost from "@/components/InfoAdditionalCost.vue";
import {
  BRow,
  BCol,
  BModal,
  BButton,
  BContainer,
  BImg,
  BTable,
} from "bootstrap-vue";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BModal,
    BButton,
    BImg,
    BTable,
    InfoAdditionalCost,
  },
  data() {
    return {
      fields: [
        { key: "product.name", label: "Nama Produk" },
        { key: "qty", label: "Qty" },
      ],
      bordered: true,
      headVariant: null,
    };
  },
  props: {
    historyDetail: {
      type: Object,
    },
    deliveryNumbers: {
      type: Array,
    },
  },
  methods: {
    sumSplitPayment(payments) {
      let total = 0;
      for (let index = 0; index < payments.length; index++) {
        const element = payments[index];
        total += parseFloat(element.amount);
      }
      return total;
    },
    paymentMethods(method) {
      const paymentMapping = {
        cash: "Tunai",
        card: "Debit/Kredit",
        transfer: "Transfer",
        giro: "Giro",
        wallet: "Deposit",
        piutang: "Piutang",
      };

      return paymentMapping[method] || "-";
    },
    sanitizeHTML(html) {
      return html?.replace(/<\/?p>/g, "");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_variables.scss";
.text-black {
  color: #000;
}
</style>
