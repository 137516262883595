<template>
  <div class="transaction">
    <header-page> Transaksi </header-page>
    <div class="transaction__filter--container py-1">
      <div
        class="row mx-0 justify-content-between w-100 flex-lg-row flex-column-reverse"
      >
        <b-col cols="12" md="5" lg="5">
          <b-button-group>
            <b-button
              v-for="(item, index) in listFilter"
              :key="`${item}-${index}`"
              :id="`button__filter__${index}`"
              class="filter--button"
              :class="{ active: item.name == filters }"
              @click="(status = item.value), (filters = item.name)"
            >
              {{ item.name }}
            </b-button>
          </b-button-group>
        </b-col>
        <b-col
          cols="12"
          md="7"
          lg="7"
          class="d-flex search__export justify-content-end"
        >
          <div class="search__input">
            <b-input-group>
              <b-form-input
                v-model="filter.search"
                placeholder="Cari nama pembeli atau nomor invoice"
              />
              <b-input-group-append is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-append>
            </b-input-group>
          </div>

          <!-- <b-button variant="outline-secondary py-1 bg-white mx-1">
            <b-img :src="require('../../assets/images/icons/export.svg')" />
            <span>Export</span>
          </b-button> -->
          <FilterButton
            @filterBase="getFilterBase"
            :is_transaction="true"
            :get-data="getData"
          />
        </b-col>
      </div>
    </div>

    <div class="d-flex justify-content-center mb-1 mt-3" v-if="isLoading">
      <b-spinner label="Loading..." />
    </div>
    <vue-good-table
      v-else
      max-height="80vh"
      :columns="columns"
      :rows="items"
      :fixed-header="false"
      :search-options="{
        enabled: false,
      }"
      :sort-options="{
        enabled: false,
      }"
      :pagination-options="{
        enabled: false,
      }"
    >
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.field == 'action'">
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle"
          />
        </span>
      </template>
      <template slot="table-row" slot-scope="props">
        <!-- Column: Pembeli -->
        <span v-if="props.column.field === 'customer.name'">
          <span>{{
            (props.row.customer && props.row.customer.name) || "-"
          }}</span>
        </span>

        <span v-else-if="props.column.field === 'invoice_number'">
          <span
            class="text-danger cursor-pointer"
            @click="getDetail(props.row.uuid)"
          >
            {{ props.row.invoice_number || "-" }}
          </span>
        </span>

        <!-- Column: Total -->
        <span v-else-if="props.column.field === 'total'">
          <span>{{ props.row.total | formatAmount }}</span>
        </span>

        <!-- Column: Payment -->
        <span v-else-if="props.column.field === 'payment_method'">
          <span class="text-capitalize">{{
            props.row.payments.map((e) => getLabel(e.payment_method)).join(", ")
          }}</span>
        </span>

        <!-- Column: Sales -->
        <span v-else-if="props.column.field === 'seller'">
          <span class="text-capitalize">{{
            (props.row.seller && props.row.seller.name) || "-"
          }}</span>
        </span>

        <!-- Column: Kasir -->
        <span v-else-if="props.column.field === 'operator.name'">
          <span class="text-capitalize">{{
            (props.row.operator && props.row.operator.name) || "-"
          }}</span>
        </span>

        <!-- Column: Status -->
        <span
          v-else-if="props.column.field === 'status'"
          class="text-capitalize"
        >
          <b-badge :variant="statusVariant(props.row.status)">
            {{
              props.row.status == "success"
                ? "Lunas"
                : props.row.status === "canceled"
                ? "Dibatalkan"
                : "Belum Lunas"
            }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown id="dropdown-dropleft" class="d-flex" right>
              <template #button-content class="btn-white text-center">
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <!-- <b-dropdown-item @click="$router.push({ name: 'invoice.index', params: { id: props.row.uuid }, query: { type: 'show_transaction' } })">
                Lihat Transaksi
              </b-dropdown-item> -->
              <!-- <b-dropdown-item v-if="checkPermission('edit transaksi')" @click="$router.push({ name: 'transaction.show', params: { id: props.row.uuid } })">
                Edit Transaksi
              </b-dropdown-item> -->
              <b-dropdown-item @click="goToDetail(props)">
                Lihat Invoice
              </b-dropdown-item>
              <b-dropdown-item
                @click="returOrder(props.row.uuid)"
                v-if="
                  props.row.status !== 'canceled' &&
                  checkPermission('add retur')
                "
              >
                <b-spinner v-if="isLoading" />
                Retur
              </b-dropdown-item>
              <b-dropdown-item
                @click="deleteOrder(props.row.uuid)"
                v-if="
                  props.row.status !== 'canceled' &&
                  checkPermission('batalkan transaksi')
                "
              >
                <b-spinner v-if="isLoading" />
                Batalkan Transaksi
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <!-- Pagination -->
    <div
      v-if="rows && !isLoading"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border"
      style="padding: 0.8rem"
    >
      <div class="d-flex align-items-center">
        <b-form-select
          v-model="filter.per_page"
          :options="['10', '15', '20']"
          class="mx-1"
        />
        <span class="text-nowrap">
          Tampilkan
          {{
            rows.total > 0 ? filter.per_page * (rows.current_page - 1) + 1 : 0
          }}
          ke
          {{
            rows.total > 0
              ? Math.min(filter.per_page * rows.current_page, rows.total)
              : 0
          }}
          dari {{ rows.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination
              class="mb-0"
              :data="rows"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </b-col>
        </b-row>
      </div>
    </div>
    <ModalRetur
      :is_edit="false"
      :returType="'order'"
      :results="returData"
      :getData="getData"
    />
    <ModalEditTransaction />
    <DetailHistoryTransaction
      :historyDetail="historyDetail"
      :delivery-numbers="deliveryNumbers"
    />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from "lodash";
import {
  BRow,
  BCol,
  BFormInput,
  BButton,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BBadge,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BSpinner,
} from "bootstrap-vue";
import { checkPermission, errorNotification } from "@/auth/utils";
import { VueGoodTable } from "vue-good-table";
import FilterButton from "@/components/BaseFilter.vue";
import HeaderPage from "@/components/HeaderPage.vue";
import DetailHistoryTransaction from "@/components/Transaction/Modal/DetailHistoryTransaction.vue";
import ModalEditTransaction from "@/components/Transaction/Modal/EditTransaksi.vue";
import ModalRetur from "@/components/Retur/Modal/ModalRetur.vue";

export default {
  title() {
    return "Transaction";
  },
  components: {
    BRow,
    VueGoodTable,
    BButton,
    BFormInput,
    HeaderPage,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BBadge,
    BImg,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    ModalEditTransaction,
    DetailHistoryTransaction,
    BCol,
    ModalRetur,
    BSpinner,
    FilterButton,
  },
  setup() {
    return {
      checkPermission,
      errorNotification,
    };
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "No. Invoice",
          field: "invoice_number",
        },
        {
          label: "Jumlah",
          field: "total",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Metode Bayar",
          field: "payment_method",
        },
        {
          label: "Kasir",
          field: "operator.name",
        },
        {
          label: "Pembeli",
          field: "customer.name",
        },
        {
          label: "Sales",
          field: "seller",
        },
        {
          label: "Tanggal",
          field: "date",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: {},
      searchTerm: "",
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
      totalBuy: this.$store.state.cashier.totalBuy,
      listFilter: [
        {
          name: "Semua",
          value: "",
        },
        {
          name: "Lunas",
          value: "success",
        },
        {
          name: "Belum Lunas",
          value: "pending",
        },
        // {
        //   name: 'Retur',
        //   value: 'retur',
        // },
        // 'Semua', 'Lunas', 'Belum Lunas', 'Retur'
      ],
      filters: "Semua",
      filter: {
        status: "",
        search: "",
        sort_type: "asc",
        per_page: 10,
      },
      optionsBank: [
        { value: "cash", title: "Tunai" },
        { value: "transfer", title: "Transfer" },
        { value: "card", title: "Kredit atau Debit" },
        { value: "giro", title: "Giro" },
        { value: "piutang", title: "Piutang" },
        { value: "wallet", title: "Wallet" },
      ],
      isLoading: false,
      returData: null,
      status: "",
      page: 1,
      items: [],
      historyDetail: {},
      deliveryNumbers: [],
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        success: "light-success",
        pending: "light-info",
        Retur: "light-warning",
        canceled: "light-danger",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
  },
  watch: {
    status: {
      handler: _.debounce(function (val) {
        this.filter.status = val;
        this.getData();
      }, 300),
      deep: true,
    },
    "filter.search": {
      handler: _.debounce(function (val) {
        this.getData();
      }, 300),
      deep: true,
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getFilterBase(value) {
      this.$router.replace({ query: "" });
      this.filter = {
        ...value,
      };
    },
    getLabel(payment) {
      const payments = {
        tunai: "Tunai",
        transfer: "Transfer Bank",
        card: "Kredit atau Debit",
        giro: "Giro",
        wallet: "Deposit",
        piutang: "Piutang",
        cash: "Tunai",
      };
      return payments[payment] || "-";
    },
    async getDetail(id) {
      this.$store
        .dispatch("order/getData", {
          uuid: `${id}/detail`,
          params: "",
        })
        .then((result) => {
          this.historyDetail = result.data.data;
          result.data.data.items.forEach((element) => {
            if (element.delivery_numbers.length) {
              this.deliveryNumbers.push(
                ...element.delivery_numbers.map((e) => e.delivery_number_note)
              );
            }
          });
          this.$bvModal.show("modal-history-detail");
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          if (err.response.data.meta.messages) {
            errorNotification(this, "Oops!", err.response.data.meta.messages);
          }
        });
    },
    deleteOrder(id) {
      this.$swal({
        title: "Konfirmasi",
        text: "Apa Anda yakin ingin membatalkan transaksi ini?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        input: "select",
        inputOptions: this.optionsBank.reduce((options, option) => {
          options[option.value] = option.title;
          return options;
        }, {}),
        inputPlaceholder: "Pilih metode pembayaran untuk refund",
        inputValidator: (value) => {
          if (!value) {
            return "Anda harus memilih metode pembayaran untuk refund!";
          }
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const refundMethod = result.value;
          this.isLoading = true;
          this.$store
            .dispatch("order/deleteData", {
              uuid: `${id}/delete`,
              payload: { refund_method: refundMethod }, // Send refund method
            })
            .then(() => {
              this.isLoading = false;
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "Order berhasil dibatalkan",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.getData();
            })
            .catch((err) => {
              this.isLoading = false;
              // eslint-disable-next-line no-console
              console.log(err);
            });
        }
      });
    },
    returOrder(id) {
      this.isLoading = true;
      this.$store
        .dispatch("order/getData", {
          uuid: `${id}/detail`,
          params: "",
        })
        .then((result) => {
          this.isLoading = false;
          this.returData = result.data.data;
          this.$store.commit("order/setDataRetur", result.data.data);
          this.$bvModal.show("modal-retur");
        })
        .catch((err) => {
          this.isLoading = false;
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    goToDetail(props) {
      this.$router.push({
        name: "invoice.index",
        params: { id: props.row.uuid },
        query: { page: this.page || 1 },
      });
    },
    getData(page = this.$route.query.page || 1) {
      this.isLoading = true;
      const queryParams = { ...this.filter };

      if (this.$route.query.date) {
        queryParams.filter_date_type = "date";
        queryParams.filter_date_value = this.$route.query.date;
      }

      // Set the page number
      queryParams.page = page;
      this.page = page;

      // Ensure paymentMethod is an array
      const paymentMethods = Array.isArray(queryParams.transactionMethod)
        ? queryParams.transactionMethod
        : [];
      delete queryParams.transactionMethod;

      // Construct the final params object
      const finalParams = {
        ...queryParams,
        ...paymentMethods.reduce((acc, method, index) => {
          acc[`payment_method[${index}]`] = method;
          return acc;
        }, {}),
      };

      this.$store
        .dispatch("order/getData", {
          uuid: "",
          params: finalParams,
        })
        .then((result) => {
          this.rows = result.data.data;
          this.items = result.data.data.data;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response.data.meta.messages) {
            errorNotification(this, "Oops!", err.response.data.meta.messages);
            this.isLoading = false;
          }
          console.log(err);
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.transaction {
  position: relative;

  .pagination__height {
    height: 80px;
  }
  @media screen and (min-width: 550px) {
    .pagination__height {
      height: 100%;
    }
  }

  .vgt-responsive {
    height: calc(100vh - 223px) !important;
    background-color: #fff;
  }

  .vgt-table.bordered {
    thead {
      th:last-child {
        text-align: center;
      }
    }
    td {
      border: none;
      border-bottom: 1px solid #ebe9f1;
      vertical-align: middle;
    }
    th {
      border: none;
      border-bottom: 1px solid #ebe9f1;
      color: #6b6c7e;
      font-weight: 700;
    }

    .bg-danger {
      &:hover,
      &:focus {
        background-color: #ffeef1 !important;
        box-shadow: none;
      }
    }
  }

  .page-item {
    .page-link {
      font-weight: 600;
      color: $secondary-2 !important;
      background-color: transparent;
    }
    &.active {
      .page-link {
        border-radius: 0;
        background-color: $light--1;
        font-weight: 600;
        color: $secondary-2 !important;
      }
    }
  }

  .page-item.prev-item ~ .page-item:nth-child(2) .page-link {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .page-item.prev-item ~ .page-item:nth-last-child(2) .page-link {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .page-item.next-item .page-link:active,
  .page-item.next-item .page-link:hover {
    background-color: transparent !important;
  }

  .pagination__container {
    width: 100%;
    height: 80px;
    position: fixed;
    bottom: 0;
    background: #ffffff;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.06);
    border: 1px solid #e4e5ec;

    .dropdown {
      button {
        font-size: 14px;
        color: $dark;
        font-weight: 600;

        &:hover {
          background-color: transparent;
        }
      }
    }

    small {
      color: $secondary-2;
      font-size: 14px;
      font-weight: 400;
    }
  }

  #dropdown-dropleft {
    .dropdown-toggle {
      border-color: transparent !important;
      background-color: transparent !important;
      color: $dark !important;

      &:hover {
        border-color: transparent !important;
        background-color: transparent !important;
        box-shadow: none !important;
      }
    }
  }
  .transaction__filter--container {
    display: flex;
    justify-content: space-between;

    .search__export {
      .search__input {
        margin-right: 8px;
        .input-group {
          width: 23.451vw;
          height: 48px;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);

          input {
            height: 48px;
            border: none;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }

          .input-group-append {
            .input-group-text {
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
              border: none;
            }
          }
        }
      }
      #search {
        width: 278px;
        height: 48px;
        border: none;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
      }

      .btn.bg-white {
        border: none !important;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
        border-radius: 8px;

        img {
          margin-right: 8px;
        }

        span {
          color: $dark;
          font-size: 14px;
          font-weight: 800;
        }
      }
    }

    .filter--button {
      border: none !important;
      color: $light--2 !important;
      background-color: transparent !important;
      border-color: transparent !important;

      &.active {
        color: $secondary !important;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          bottom: -48%;
          left: 0;
          width: 50%;
          margin-left: 25%;
          border-radius: 4px 4px 0px 0px;
          border: 2px solid $secondary;
        }
      }

      &:hover {
        box-shadow: none !important;
      }
    }
  }
  // .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th, .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  //   background-color: #fff;
  //   color: $light--description;
  //   padding: 13.5px 0px 13.5px 24px;
  // }

  // .table-responsive {
  //   background: #fff;
  //   height: 66vh;

  //   table {
  //     td {
  //       padding-top: 21px;
  //       padding-bottom: 14px;
  //       padding-left: 24px;
  //       padding-right: 0px;
  //       color: $dark;
  //       font-weight: 700;
  //       font-size: 14px;
  //     }

  //     thead {
  //       th {
  //         &:last-child {
  //           text-align: center;
  //           padding-right: 15px !important;
  //           width: 8vw;
  //         }
  //       }
  //     }
  //   }
  // }

  // .b-table-sticky-header {
  //   max-height: 66vh;
  // }
}
</style>
